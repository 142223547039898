import "./initializers";
import "./components";

import changePaymentKind from "./components/gateways/changePaymentKind";
import { initializeListeners } from "../site/components/gateways";
import { refreshVMasker } from "./initializers/vmasker";
import dayjs from "dayjs";

function checkoutActions() {
  if (
    $("#address_zip_code_valid").val() == "true" ||
    $("#customer_addresses_attributes_0_zip_code_valid").val() == "true"
  ) {
    $(".form_address").removeClass("d-none");
  }
  $(".city_search").css("pointer-events", "none");
  $(".city_search").css("touch-action", "none");
  $(".state_search").css("pointer-events", "none");
  $(".state_search").css("touch-action", "none");
  $(document).ready(function () {
    refreshVMasker();
    $(".freight-radio").on("click", function () {
      let cep = $(".cep").val();
      let service = $(this).attr("date-service");
      let cart = $(this).attr("date-cart");
      if ($(this).val() != "") {
        $.get(
          "/payments/calculate_freight/" +
            $(this).val() +
            "?cep=" +
            cep +
            "&service=" +
            service +
            "&cart=" +
            cart,
          {},
          function () {},
          "script"
        );
      }
    });

    $("#customer_email_checkout").on("change", function () {
      // console.log($(this).val());
      let text = "E-mail não é válido";
      if (validate($(this), $(".customer_email"), text)) {
        find_or_create_customer($(this), $(".customer_email"));
        window.dispatchEvent(
          new CustomEvent("email_cadastro", {
            detail: { email: $(this).val() },
          })
        );
      }
    });

    $("#email").on("input", validate);

    let radioValue = $(
      "input[name='seller[profile_seller_attributes][entity]']:checked"
    ).val();
    if (radioValue == "1") {
      // $('.individual').hide()
      $(".enterprise").show();
    } else {
      // $('.individual').show()
      $(".enterprise").hide();
    }

    $("input[name='seller[profile_seller_attributes][entity]']").click(
      function () {
        let radioValue = $(
          "input[name='seller[profile_seller_attributes][entity]']:checked"
        ).val();
        if (radioValue == "1") {
          // $('.individual').hide()
          $(".enterprise").show();
        } else {
          // $('.individual').show()
          $(".enterprise").hide();
        }
      }
    );
    $("#state_input_address").change(function () {
      let id_state = $("#state_input_address").val();
      $.get("/list/cities/" + id_state, function (data) {
        let x;
        $("#city").html("");
        $("#city").append("<option value> Selecione a cidade </option >");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#city").append(option);
        }
      });
    });

    $(".state_search").change(function () {
      let id_state = $(".state_search").val();
      $.get("/list/cities/" + id_state, function (data) {
        let x;
        $("#city").html("");
        $("#city").append("<option value> Selecione a cidade </option >");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#city").append(option);
        }
      });
    });

    $(".cep_search").keyup(function () {
      const cep = $(this).val();
      const filter_cep = cep.replace(/\D/g, "");
      $(this).css({ "border-color": "rgb(206, 212, 218)" });
      $(".cep_error").remove();
      if (filter_cep.length == 8) {
        $.get(
          `https://viacep.com.br/ws/${filter_cep}/json/`,
          {},
          function (data) {
            if (!data.erro) {
              // CEP behavior on Checkout
              $("#address_zip_code_valid").val(true);
              setTimeout(() => {
                $(".form_address").removeClass("d-none");
                $("#address_zip_code").addClass("is-valid");
              }, 700);
              setTimeout(() => {
                $(".load_delivery").removeClass("d-none");
              }, 700);
              //End CEP behavior

              $("#customer_addresses_attributes_0_zip_code_valid").val(true);
              $(".neighborhood_search").val(data.bairro);
              $(".street_search").val(data.logradouro);
              let state_id = $(
                `.state_search option:contains(- ${data.uf})`
              ).val();
              $(".state_search").val(state_id);
              $(".state_search").css("pointer-events", "none");
              $(".state_search").css("touch-action", "none");
              $(".city_search").val("");
              $(".city_search").html("");
              $.get(`/list/cities/${state_id}`, {}, function (cities_data) {
                $(".city_search").append(
                  '<option value="">Selecione a Cidade</option>'
                );
                cities_data.forEach(function (city) {
                  var newOption = new Option(city.name, city.id, false, false);
                  // Append it to the select
                  $(".city_search").append(newOption);
                  if (city.name == data.localidade) {
                    $(".city_search").val(city.id);
                  }
                });
                $(".city_search").css("pointer-events", "none");
                $(".city_search").css("touch-action", "none");
              });

              $.get(
                "/payments/delivery_search/" + cep,
                {},
                function (data) {
                  // console.log(data);
                  if (data != 0) {
                    // $(".load_delivery").html('');
                    // $(".load_delivery").html('');
                  } else {
                    // console.log("cep error");
                  }
                },
                "script"
              );
            } else {
              // $("#address_zip_code_valid").prop("checked", false);
              $("#address_zip_code_valid").val(false);
              $("#customer_addresses_attributes_0_zip_code_valid").val(false);
              //cep não encontrado.
              limpa_formulario_cep();
              $(".invalid-feedback").remove();
              $(".cep_search").css({ "border-color": "rgb(255, 0, 0)" });
              $("#cep_div").append(
                "<span class='cep_error small text-danger'>CEP não encontrado. </span>"
              );
              $(".state_search").css("pointer-events", "auto");
              $(".city_search").css("pointer-events", "auto");
              $(".state_search").css("touch-action", "auto");
              $(".city_search").css("touch-action", "auto");
            }
          }
        );
      } else {
        //cep is invalid.
        $(".load_delivery").addClass("d-none");
        $(".form_address").addClass("d-none");
        $("#address_zip_code").removeClass("is-valid");

        limpa_formulario_cep();
        $(".invalid-feedback").remove();
        $(".cep_search").css({ "border-color": "rgb(255, 0, 0)" });
        $("#cep_div").append(
          "<span class='cep_error small text-danger'>CEP inválido. </span>"
        );
        // $("#address_zip_code_valid").prop("checked", false);
        $("#address_zip_code_valid").val(false);
        $("#customer_addresses_attributes_0_zip_code_valid").val(false);
        $(".state_search").css("pointer-events", "auto");
        $(".city_search").css("pointer-events", "auto");
        $(".state_search").css("touch-action", "auto");
        $(".city_search").css("touch-action", "auto");
      }
    });

    function limpa_formulario_cep() {
      // Limpa valores do formulário de cep.
      $(".cep_error").remove();
      $(".neighborhood_search").val("");
      $(".street_search").val("");
      $(".state_search").val("");
      $(".city_search").val("");
      $(".complement_search").val("");
      $(".number_search").val("");
    }
  });

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validate = (element, customer_email, text) => {
    const $result = customer_email;
    const email = element;
    $(".remove").remove();

    if (validateEmail(email.val())) {
      email.removeClass("is-invalid");
      email.addClass("is-valid");
      return true;
    } else {
      $(".invalid-feedback").remove();
      email.removeClass("is-valid ");
      email.addClass("is-invalid");
      // $result.append('<div class="invalid-feedback remove">' + text + "</div>");
      $result.append(
        '<div class="invalid-feedback remove">E-mail inválido</div>'
      );
      return false;
    }
  };

  /**
   * Display a pop-up for first time-purchases to give users discount
   */
  function displayFirstTimePurchaseCoupon() {
    $(".checkout-popup").removeClass("d-none");
  }

  const find_or_create_customer = (element, customer_email) => {
    const email = element.val();
    const url = "/payments/find_or_create_customer";
    $(".remove").remove();
    element.removeClass("is-invalid");
    element.removeClass("is-valid ");
    $(".action-button").attr("disabled", true);
    $(".action-button").html(
      '<span class="mdi mdi-spin mdi-loading"></span><span class="mx-2">Validando e-mail...</span>'
    );
    // $(".cep").attr("disabled", false);
    $.post(url, { customer_email: email }, function (data) {
      // console.log(data);
      if (data.status == 200) {
        const result = JSON.parse(data.result);

        if (!result) {
          displayFirstTimePurchaseCoupon();
        }

        $(".remove").remove();
        // $(".resume_cart :input").attr("disabled", false);
        //$("#discount_coupon").attr("disabled", false);
        $(".order_bump").removeClass("d-none");
        element.removeClass("is-invalid");
        element.removeClass("is-valid ");
        element.addClass("is-valid ");
        if (result) {
          $("#customer_name").val(result.name);
          $("#customer_document").val(result.document);
          $("#customer_phone_attributes_number").val(
            result.phone ? result.phone.number : ""
          );
        }
      } else {
        $(".invalid-feedback").remove();
        element.addClass("is-invalid");
        customer_email.append(
          // '<div class="invalid-feedback remove">' + data.result + "</div>"
          '<div class="invalid-feedback remove">E-mail inválido</div>'
        );
        // console.log(data);
      }

      $(".action-button").attr("disabled", false);
      $(".action-button").html("Proximo");
    });
  };

  if (
    $(".customer_email .invalid-feedback").html() ==
    "E-mail não pode ficar em branco e E-mail E-mail inválido"
  ) {
    $(".customer_email .invalid-feedback").html(
      "E-mail não pode ficar em branco"
    );
  }

  if (
    $(".customer_email .invalid-feedback").html() ==
    "E-mail não pode ficar em branco, E-mail E-mail inválido e E-mail inválido"
  ) {
    $(".customer_email .invalid-feedback").html(
      "E-mail não pode ficar em branco"
    );
  }

  if (
    $(".address_zip_code .invalid-feedback").html() == "CEP CEP não é válido."
  ) {
    $(".address_zip_code .invalid-feedback").html("CEP não é válido.");
  }
}

checkoutActions();

window.copyBarcode = () => {
  let $temp = $("<input>");
  $("body").append($temp);
  $temp.val($("#barcode").text()).select();
  document.execCommand("copy");
  $(".copyBarcodeLink").html("Link Copiado");
  $(".copyBarcodeLink").addClass("btn-success");
  $(".copyBarcodeLink").removeClass("btn-info");

  $temp.remove();
};

window.checkoutActions = checkoutActions;
window.refreshVMasker = refreshVMasker;

document.addEventListener("turbo:before-stream-render", function (e) {
  setTimeout(() => {
    initializeListeners();
    refreshVMasker();
    new CheckoutActions();
  }, 500);
  document
    .querySelector("#card-checkout-landing-page")
    .scrollIntoView({ behavior: "smooth" });
});

// initializing the checkout resume collapsed
(() => {
  const $resumeCard = $("#collapseResumeCheckout");
  const $accordionButton = $("#accordionResumeCheckout").find(
    ".accordion-button"
  );

  const clientWidth = $(document).width();

  if (clientWidth <= 991) {
    $accordionButton.attr("aria-expanded", false);
    $resumeCard.removeClass("show");
  }

  if ($("#collapseResumeCheckout") && clientWidth >= 991) {
    $resumeCard.addClass("show");
  }
})();
